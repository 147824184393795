<template>
    <button @click="show = true" class="btn ml-2 btn-success btn-sm">
        <i class="fa fa-upload"></i> Tải dữ liệu
        <b-modal v-model="show" title="Tải danh sách phục hồi chức năng" @close="close" no-close-on-esc no-close-on-backdrop
                 no-enforce-focus>
            <!-- <div class="form-group">
                <label><b>Tải xuống tập tin mẫu</b></label>
                <div>
                    <button @click="downloadTemplate" class="btn btn-sm btn-secondary mr-1 mb-2">
                        <i class="fa fa-file-excel"></i> {{templateName}}
                    </button>
                </div>
            </div> -->
            <div class="form-group">
                <label><b>Chọn tập tin</b></label>
                <b-form-file v-model="file" :disabled="reading||totalRows>0"
                             placeholder="Chọn tập tin từ máy tính hoặc kéo thả vào đây"
                             drop-placeholder="Chọn tập tin từ máy tính hoặc kéo thả vào đây"
                             browse-text="Chọn tập tin" 
                             accept="application/x-iwork-keynote-sffnumbers,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"></b-form-file>
            </div>
            <b-form-checkbox v-model="updated" value="1" unchecked-value="0">
                Cho phép cập nhật thông tin khi trùng mã người khuyết tật và ngày can thiệp gần nhất
            </b-form-checkbox>
            <div class='mb-3' ></div>
            <div v-if="reading" class="alert alert-warning">
                <b-spinner small variant="primary" class='mr-1'></b-spinner>
                <span>Đang đọc file. Quá trình này có thể sẽ tốn nhiều thời gian nếu file tải lên có dung lượng lớn. Xin đừng tắt cửa sổ trình duyệt cho đến khi quá trình tải lên hoàn tất!</span>
            </div>
            <div v-if="importing" class="alert alert-warning">
                <b-spinner small variant="primary" class='mr-1'></b-spinner>
                <span>Đang tải dữ liệu lên... Xin hãy kiên nhẫn, việc tải lên sẽ mất một chút thời gian nếu file của bạn quá lớn</span>
            </div>
            <div class="mb-3" v-if="totalRows">
                <b-progress :max="totalRows">
                    <b-progress-bar :value="successCount+errorCount"
                                    :label="`${(((successCount + errorCount) / totalRows) * 100).toFixed(0)}%`"></b-progress-bar>
                </b-progress>
                <div class="text-center mt-2">Xử lý {{ successCount + errorCount }}/{{ totalRows }} dòng | <span :class="[errorCount && errorCount > 0? 'text-danger':'']" >Vấn đề: {{ errorCount }} </span>
                    <a v-if='errorCount > 0' @click="downloadErrors" class="btn-link text-primary" href="javascript:;">[Chi tiết]</a>
                </div>
            </div>
            <template v-slot:modal-footer>
                <button class="btn btn-secondary" @click="cancel" v-if="!reading&&importing">Dừng tải</button>
                <button class="btn btn-primary" @click="reset" v-if="!reading&&!importing&&totalRows>0">Làm lại</button>
                <button v-if="totalRows===0" class="btn btn-primary" @click="process" :disabled="reading||importing">
                    <b-spinner small v-if="reading||importing"></b-spinner>
                    Tải lên
                </button>
            </template>
        </b-modal>
    </button>
</template>

<script>
    import Import from "@/mixins/Import";

    export default {
        props: ['client_id', 'params'],
        mixins: [Import],
        data() {
            return {
                service: '/patient-phcn',
                fileName: `can-thiep-phuc-hoi-chuc-nang`,
                templateName: 'Can-thiep-phuc-hoi-chuc-nang',
                reloadBus: "reloadPatientPhcn",
                headers: [
                    {header: 'code', key: 'code',label: 'Mã NKT*', width: 30, desc: "Nhập định dạng chữ (BHYT/CCCD/CMT/Mã hồ sơ NKT - tối đa 100 ký tự)"},
                    {header: 'fullname', key: 'fullname', label: 'Họ và tên*', width: 30, desc: "Nhập định dạng chữ (tối đa 200 ký tự)"},
                    {header: 'dob', key: 'dob', label: 'Năm sinh*', width: 15, desc: "Nhập định dạng số (yyyy - Giá trị từ 1911 đến năm nhập dữ liệu)"},
                    {header: 'province_id', key: 'province_id', label: 'Tỉnh/Thành phố*', width: 25, desc: "Nhập định dạng chữ (" + this.$params.provinceExport.filter(item => item.value != null).map(item => item.text).toString() + ")"},
                    {header: 'district_id', key: 'district_id', label: 'Quận/Huyện*', width: 25, desc: "Nhập định dạng chữ"},
                    {header: 'ward_id', key: 'ward_id', label: 'Xã/Phường*', width: 25, desc: "Nhập định dạng chữ"},
                    {header: 'race_id', key: 'race_id', label: 'Dân tộc', width: 15, desc: "Nhập định dạng chữ"},
                    {header: 'gender_id', key: 'gender_id', label: 'Giới tính*', width: 15, desc: "Nhập 01 giá trị dạng chữ (Nam/Nữ/Khác)"},
                    {header: 'types', key: 'types', label: 'Dạng tật (6 nhóm, có để đa khuyết tật)*', width: 25, desc: "Nhập 01 hoặc nhiều giá trị số từ 1 đến 6 (Trong đó: 1. Khuyết tật vận động/2. Khuyết tật nghe, nói/3. Khuyết tật nhìn/4. Khuyết tật thần kinh, tâm thần/5. Khuyết tật trí tuệ/6. Khuyết tật khác)."},
                    {header: 'agent_orange_victim', key: 'agent_orange_victim', label: 'Nạn nhân da cam*', width: 25, desc: "Nhập 01 giá trị số từ 1 đến 3 (Trong đó: " + this.$params.patient.victimOptions.filter(item => item.value != null).map(item => item.text).toString() + ")"},
                    {header: 'degree_disability', key: 'degree_disability', label: 'Mức độ khuyết tật*', width: 25, desc: "Nhập 01 giá trị số từ 1 đến 4 (Trong đó: 1.Nhẹ/2.Nặng/3.Đặc biệt nặng/4. Không xác định)"},
                    {header: 'mine_victim', key: 'mine_victim', label: 'Nạn nhân bom mìn', width: 25, desc: "Nhập giá trị số từ 1 đến 2 (Trong đó: " + this.$params.patient.mineVictimOptions.filter(item => item.value != null).map(item => item.text).toString() + ")"},
                    {header: 'tool', key: 'tool', label: 'Cung cấp dụng cụ trợ giúp từ dự án*', width: 25, desc: "Nhập 01 giá trị số từ 1 đến 2 (Trong đó: 1. Có/2. Không)"},
                    {header: 'start_time', key: 'start_time', label: 'Ngày bắt đầu can thiệp', width: 25, desc: "Nhập định dạng dd/mm/yyyy. Thời gian phải trước hoặc bằng ngày nhập dữ liệu"},
                    {header: 'rate_time', key: 'rate_time', label: 'Ngày can thiệp gần nhất*', width: 25, desc: "Nhập định dạng dd/mm/yyyy. Thời gian phải trước hoặc bằng ngày nhập dữ liệu và bằng hoặc sau ngày bắt đầu can thiệp"},
                    {header: 'evaluate_time', key: 'evaluate_time', label: 'Ngày đánh giá gần nhất', width: 25, desc: "Nhập định dạng dd/mm/yyyy. Thời gian phải trước hoặc bằng ngày nhập dữ liệu và bằng hoặc sau ngày bắt đầu can thiệp"},
                    {header: 'adl', key: 'adl', label: 'Kết quả đánh giá chức năng sinh hoạt (ADL)', width: 25, desc: "Nhập 01 giá trị số từ 1 đến 2 (Trong đó: " + this.$params.patient.resultPhcn.filter(item => item.value != null).map(item => item.text).toString() + ")"},
                    {header: 'end_time', key: 'end_time', label: 'Ngày kết thúc can thiệp', width: 25, desc: "Nhập định dạng dd/mm/yyyy. Thời gian phải trước hoặc bằng ngày nhập dữ liệu và bằng hoặc sau ngày bắt đầu can thiệp"},
                ],
                indexRow: 4,
            }
        },
        methods: {
            async save(item) {
                if (this.updated == 1) {
                    await this.$service.post(`${this.service}/import?t=u`, item);
                } else {
                    await this.$service.post(`${this.service}/import?t=c`, item);
                }
            },
            async getItem(headerRow, row) {
                let vm = this;
                let item = {
                    client_id: this.client_id
                };
                for (let j = 1; j <= headerRow.cellCount; j++) {
                    let key = headerRow.getCell(j).value;
                    let value;
                    if (row.getCell(j).isHyperlink) {
                        value = row.getCell(j).value.text;
                    } else {
                        value = row.getCell(j).value;
                    }

                    if (headerRow.getCell(1).value !== 'code' || headerRow.getCell(2).value !== 'fullname' || headerRow.getCell(13).value !== 'tool' || headerRow.getCell(17).value !== 'adl') {
                        throw new Error("File tải lên chưa đúng mẫu!");
                    }

                    if (value !== null && value !== '') {
                        if (typeof value === 'string') {
                            value = value.trim();
                        }
                        if (['gender_id'].includes(key)) {
                            let gender_id = vm.$utils.createAlias(value);
                            item.gender_id = gender_id == ('nam' || 0) ? 0 : gender_id == ('nu' || 1) ? 1 : 2;
                        } else if (['race_id'].includes(key)) {
                            let idRs = [];
                            let nameRS = [];
                            const RaceS = {};
                            for (let i in this.params.race) {
                                let r = this.params.race[i];
                                idRs.push(i);
                                let nameRaceS = vm.$utils.createAlias(r);
                                nameRS.push(nameRaceS);
                                RaceS[nameRaceS] = i;
                            }
                            if (idRs.includes(value)) {
                                item.race_id = value + '';
                            } else if (nameRS.includes(vm.$utils.createAlias(value))) {
                                item.race_id = RaceS[vm.$utils.createAlias(value)];
                            } else {
                                item.race_id = -1;
                            }
                        } else if (['fullname'].includes(key)) {
                            item[key] = value + "";
                        } else if (['code'].includes(key)) {
                            item.code = value.toString().toUpperCase();
                        } else if (['types'].includes(key)) {
                            let result = [];
                            let key = this.getKeyOption(this.$params.patient.typeOptions);
                            if (value.toString().includes(",")) {
                                let array = value.split(',');
                                array.forEach((entry) => {
                                    if (key.includes(entry.trim())) {
                                        result.push(entry.trim());
                                    } else {
                                        result.push(-1);
                                    }
                                });
                            } else {
                                if(key.includes(value.toString())){
                                    result.push(value.toString());
                                }   else {
                                    result.push(-1);
                                }
                            }
                            item.types = result;
                        } else if (['agent_orange_victim'].includes(key)) {
                            let key = this.getKeyOption(this.$params.patient.victimOptions);
                            if(key.includes(value.toString().trim())){
                                item.agent_orange_victim = value.toString().trim()
                            } else {
                                item.agent_orange_victim = -1;
                            }
                        } else if (['degree_disability'].includes(key)) {
                            let key = this.getKeyOption(this.$params.patient.degreeDisabilityOptions);
                            if(key.includes(value.toString().trim())){
                                item.degree_disability = value.toString().trim()
                            } else {
                                item.degree_disability = -1;
                            }
                        } else if (['mine_victim'].includes(key)) {
                            let key = this.getKeyOption(this.$params.patient.mineVictimOptions);
                            if(key.includes(value.toString().trim())){
                                item.mine_victim = value.toString().trim()
                            } else {
                                item.mine_victim = -1;
                            }
                        } else if (['adl'].includes(key)) {
                            let key = this.getKeyOption(this.$params.patient.resultPhcn);
                            if(key.includes(value.toString().trim())){
                                item.adl = value.toString().trim()
                            } else {
                                item.adl = -1;
                            }
                        } else if (['tool'].includes(key)) {
                            let key = this.getKeyOption(this.$params.patient.interventionOptions);
                            if(value){
                                if(key.includes(value.toString().trim())){
                                item.tool = value.toString().trim()
                                } else {
                                    item.tool = -1;
                                }
                            } else {
                                item.tool = '';
                            }
                        } else if (['start_time'].includes(key)) {
                            value = this.getDate(value, "DD/MM/YYYY");
                            item.start_time = value;
                        } else if (['rate_time'].includes(key)) {
                            value = this.getDate(value, "DD/MM/YYYY");
                            item.rate_time = value;
                        }  else if (['end_time'].includes(key)) {
                            value = this.getDate(value, "DD/MM/YYYY");
                            item.end_time = value;
                        } else if (['evaluate_time'].includes(key)) {
                            value = this.getDate(value, "DD/MM/YYYY");
                            item.evaluate_time = value;
                        } else if (['dob'].includes(key)) {
                            if(value == 1970){
                                item.dob = 1000;
                            } else {
                                item.dob = Date.parse(value);
                            }
                        } else {
                            item[key] = value;
                        }
                    } else {
                        item[key] = null;
                    }
                }
                return item;
            }
        }
    }
</script>